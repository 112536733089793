import store from "@/store";

export default {
  methods: {
    getPaymentLogos(key, size = "large") {
      //   return store.state.app.paymentLogos;
      const payment = store.state.app.paymentLogos.find(item => item.displayName === key);
      if (payment) {
        return payment.logos[size];
      }
      // return "UNDEFINED_" + key;
      return false;
    },
    getPaymentLogosByType(type, size = "large") {
      const payment = store.state.app.paymentLogos.find(item => item.tradeType === type);
      if (payment) {
        return payment.logos[size];
      }
      // return "UNDEFINED_" + type;
      return false;
    }
  }
};
